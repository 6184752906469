<template>
  <div class="interview">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="650px"
    >
      <div class="examRule_head flex">
        <span>审核{{ message.audit_status == 1 ? '中' : message.audit_status == 2 ? '成功' : '失败' }}详情</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="interview-detail">
        <!--message-pop start-->
        <div class="message-pop">
          <div class="pop-hd">
            <div class="hd-l">
              <span class="name">{{ message.basic_name }}</span>
              <span class="sex">{{ message.basic_sex }}</span>
              <span class="tag">{{ getClass(message.basic_booth_type) }}</span>
              <span class="tag orange" v-for="(item,index) in basic_finish_type" :key="index">{{ item }}</span>
            </div>
            <div class="hd-price">
              预算<span class="color_red size-20"
                >{{ message.total_price }}元/月</span
              >
            </div>
          </div>
          <div class="detail-item">
            <div class="detail-li">
              <span class="color-9">意向类型：</span
              >{{ message.basic_purpose_type }}
            </div>
            <div class="detail-li">
              <span class="color-9">经纪人：</span
              >{{ message.basic_broker_name }}
            </div>
            <div class="detail-li">
              <span class="color-9">意向位置：</span>{{ message.basic_city }}
            </div>
            <div class="detail-li">
              <span class="color-9">最近带看日期：</span
              >{{ message.basic_look_date }}
            </div>
            <div class="detail-li">
              <span class="color-9">录入日期：</span>{{ message.basic_time }}
            </div>
            <div class="detail-li">
              <span class="color-9">客户来源：</span
              >{{ message.basic_client_source }}
            </div>
          </div>
        </div>
        <!--message-pop end-->
        <div class="tit">修改信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">意向类型：</span>{{ message.purpose_type }}
          </div>
          <div class="detail-li">
            <span class="color-9">意向位置：</span>{{ message.city }}
          </div>
          <div class="detail-li">
            <span class="color-9">意向房型：</span
            >{{ message.purpose_house_type }}
          </div>
          <div class="detail-li">
            <span class="color-9">预算：</span>{{ message.total_price }}元
          </div>
          <div class="detail-li">
            <span class="color-9">客户等级：</span
            >{{ getClass(message.booth_type) }}
          </div>
          <div class="detail-li">
            <span class="color-9">联系方式：</span>{{ message.mobile }}
          </div>
        </div>
        <div class="tit">其他信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">提交时间：</span> {{ message.createtime }}
          </div>
        </div>
        <div class="detail-item flex m-t-40 p-l-30 p-r-30" v-if="message.audit_status == 1 && type == 1 && userInfo.position == 1">
          <el-button @click="changeItem(1)">拒绝</el-button>
          <el-button @click="changeItem(2)">同意</el-button>
        </div>
      </div>
    </el-dialog>
    <audit-pop-re ref="pop" @succend="succend"></audit-pop-re>
  </div>
</template>

<script>
import auditPopRe from './AuditPopRe.vue'
import { mapGetters } from "vuex";
export default {
  components:{auditPopRe},
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "region"]),
  },
  data() {
    return {
      id:null,
      type:1,//
      dialogVisible: false,
      message: {}, //详情
      basic_finish_type:[]
    };
  },
  methods: {
    succend () {
      this.$emit('succend')
      this.dialogVisible = false;
    },
    changeItem (type) {
      if (type == 1) {
        this.$refs.pop.open(this.id,4)
      } else {
        this.$axios.agreeClients({id:this.id}).then(res=>{
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.$emit('succend')
          this.dialogVisible = false;
        })
      }
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id,type) {
      this.id = id
      this.type = type
      this.$axios.auditClientsDetail({ id }).then((res) => {
        this.dialogVisible = true;
        this.message = res.data;
        this.basic_finish_type = res.data.basic_finish_type.split(',')
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.form {
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-input {
    width: 320px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
.interview-detail {
  padding: 10px 30px 20px 30px;
  .tit {
    font-size: 18px;
    margin: 20px 0 10px 0px;
  }
  .detail-item {
    display: flex;
    flex-wrap: wrap;
    /deep/ .el-button {
      width: 240px;
      height: 56px;
      font-size: 18px;
      border-radius: 8px;
      border: none;
      &:first-child {
        background: #FDDFDF;
        color: #FF5151;
      }
      &:last-child {
        color: #FFFFFF;
        background: #3273F6;
      }
    }
  }
  .detail-li {
    width: 50%;
    margin: 10px 0px;
    font-size: 16px;
  }
}
.message-pop {
  box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
  border-radius: 4px;
  padding: 20px;
  margin-top: 15px;
  .pop-hd {
    display: flex;
    justify-content: space-between;
    .name {
      font-size: 18px;
    }
    .hd-price {
      font-size: 18px;
    }
    .size-20 {
      font-size: 20px;
    }
    .sex {
      font-size: 16px;
      margin: 0 5px;
    }
    .tag {
      line-height: 28px;
      padding: 0 10px;
      border-radius: 3px;
      background: #d5e3ff;
      font-size: 14px;
      color: #3273f6;
      margin: 0 5px;
    }
    .tag.orange {
      background: rgba(255, 117, 42, 0.1);
      color: #ff752a;
    }
  }
}
</style>
