<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div
          @click="changeStatus(index)"
          class="li"
          :class="{ active: statusIndex == index }"
          v-for="(item, index) in 3"
          :key="index"
        >
          {{ index == 0 ? "审核中" : index == 1 ? "审核成功" : "审核失败" }}
        </div>
      </div>
      <div class="message-select">
        <el-select @change="init" v-model="period" placeholder="类型" style="min-width: 300px">
          <el-option
            v-for="item in attributeList"
            :key="item.type"
            :label="item.name"
            :value="item.type"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--honor-head start-->
    <div class="honor-head">
      <div class="head-nav">
        <div @click="changeType(0)" class="li" :class="{active:typeIndex == 0}">我的变更</div>
        <div v-if="userInfo.position != 5" @click="changeType(1)" class="li" :class="{active:typeIndex == 1}">他人变更</div>
      </div>
    </div>
    <!--honor-head end-->
    <!--ind-main start-->
    <div class="ind-main" v-if="list.length">
      <!--房源-->
      <div v-if="period === 'House'">
        <div class="message-list" v-if="typeIndex == 0">
          <!--record_table start-->
          <div class="record_table">
            <div class="record_tr" @click="hrefDetail(item,typeIndex)" v-for="(item, index) in list" :key="index">
              <div class="record_td">
                <div class="td">
                    <span class="color-9">时间：</span>{{ item.createtime }}
                  </div>
              </div>
              <div class="record_td">
                <div class="td">
                    <span class="color-9">房源类型：</span>{{ getHouseType(item.fang_type) }}
                  </div>
              </div>
              <div class="record_td">
                <div class="td">
                  <span class="color-9">房源名称：</span>{{ item.house_name }}
                </div>
              </div>
            </div>
          </div>
          <!--record_table end-->
        </div>
        <!--audit-list start-->
        <div class="audit-list" v-else>
          <!--audit-item start-->
          <div class="audit-item" @click="hrefDetail(item,typeIndex)" v-for="(item,index) in list" :key="index">
            <div class="item-l">
              <div class="item-flex">
                <div class="item-td">
                  <span class="color-9">修改人：</span> {{ item.name }}
                </div>
                <div class="item-td">
                  <span class="color-9">电话：</span> {{ item.mobile }}
                </div>
                <div class="item-td">
                  <span class="color-9">房源类型：</span> {{ getHouseType(item.fang_type) }}
                </div>
                <div class="item-td">
                  <span class="color-9">房源名称：</span> {{ item.house_name }}
                </div>
              </div>
            </div>
            <div class="item-r">
              <div class="flex-end" v-if="statusIndex == 0 && typeIndex == 1">
                <el-button class="audit-list-btn red m-r-10 m-b-10"
                  >拒绝</el-button
                >
                <el-button class="audit-list-btn blue m-b-10">同意</el-button>
              </div>
              <div class="item-date">{{ item.createtime }}</div>
            </div>
          </div>
          <!--audit-item end-->
        </div>
        <!--audit-list end-->
      </div>
      <!--客源-->
      <div v-if="period === 'Tourist'">
        <div class="message-list" v-if="typeIndex == 0">
          <div class="message-list">
            <!--record_table start-->
            <div class="record_table">
              <div class="record_tr" v-for="(item, index) in list" :key="index">
                <div class="record_td">
                  <div class="td">
                    <span class="color-9">时间：</span>{{ item.createtime }}
                  </div>
                </div>
                <div class="record_td">
                  <div class="td">
                    <span class="color-9">客户姓名：</span>{{ item.name }}
                  </div>
                </div>
                <div class="record_td">
                  <div class="td">
                    <span class="color-9">性别：：</span>{{ item.sex }}
                  </div>
                </div>
                <div class="record_td">
                  <div class="color_blue" @click="$refs.mpop.open(item.id,typeIndex)">
                    查看详情>>
                  </div>
                </div>
              </div>
            </div>
            <!--record_table end-->
          </div>
        </div>
        <!--audit-list start-->
        <div class="audit-list" v-else>
          <!--audit-item start-->
          <div class="audit-item" v-for="(item,index) in list" :key="index">
            <div class="item-l">
              <div class="item-flex">
                <div class="item-td">
                  <span class="color-9">客户姓名：</span> {{ item.name }}
                </div>
                <div class="item-td">
                  <span class="color-9">修改人：</span> {{ item.sub_name }}
                </div>
                <div class="item-td">
                  <span class="color-9">电话：</span> {{ item.mobile }}
                </div>
                <div class="item-td">
                  <span class="color-9">时间：</span> {{ item.createtime }}
                </div>
              </div>
            </div>
            <div class="item-r" v-if="userInfo.position == 1">
              <div class="flex-end">
                <el-button @click="$refs.mpop.open(item.id)" class="audit-list-btn red m-r-10"
                  >拒绝</el-button
                >
                <el-button class="audit-list-btn blue" @click="$refs.mpop.open(item.id,typeIndex)">同意</el-button>
              </div>
              <!-- <div class="item-date">2020-12-28 14:26</div> -->
            </div>
          </div>
          <!--audit-item end-->
          <!--audit-item end-->
        </div>
        <!--audit-list end-->
      </div>
      <div class="paging" v-if="list.length">
        <el-pagination
          background
          @current-change="chagnePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--ind-main end-->
    <!-- 客源详情弹框 S -->
    <message-pop ref="mpop" @succend="init"></message-pop>
    <!-- 客源详情弹框 E -->
  </div>
</template>

<script>
import MessagePop from "../../components/MessagePop";
import { mapGetters } from "vuex";
export default {
  components: { MessagePop },
  computed: {
    time() {
      switch (this.period) {
        case "House":
          return "房源";
        case "Tourist":
          return "客源";
        default:
          return "";
      }
    },
    ...mapGetters(["userInfo"]),
  },

  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      attributeList: [
        {
          name: "房源",
          type: "House",
        },
        {
          name: "客源",
          type: "Tourist",
        },
      ],
      period: "House", // 默认为房源
      typeIndex: 0, //身份：1 我的；2 他人
      statusIndex: 0, //状态：1 审核中；2 审核通过；3 审核失败
    };
  },
  methods: {
    chagnePage (e) {
      this.page = e
      this.getList()
    },
    hrefDetail (item,type) {
      this.$router.push({path:'/personage/messageDetail',query:{id:item.id,type:type}})
    },
    //切换身份
    changeType (index) {
      this.typeIndex = index
      this.init()
    },
    //切换状态
    changeStatus(index) {
      this.typeIndex = 0;
      this.statusIndex = index;
      this.init();
    },
    init() {
      this.page = 1;
      this.list = [];
      this.getList();
    },
    //获取房源客源列表
    getList() {
      this.$axios[this.period == "House" ? "houseChangeList" : "auditClientsList"]({
        role_type: this.typeIndex + 1,
        status: this.period == 'House' ? this.statusIndex : this.statusIndex + 1,
        page:this.page
      }).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #ebebeb;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}
.message-select {
  /deep/ .el-select {
    width: 100px;
    min-width: 100px !important;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
  }
}

.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;
  .head-nav {
    display: flex;
  }
  .li {
    cursor: pointer;
    margin-right: 55px;
    line-height: 36px;
  }
  .nav-link {
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }
  .active {
    color: #3273f6;
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.ind-add-btn-o {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background: #ffa740;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  border: none;
}
.ind-main {
  background: #fff;
  padding: 10px 0;
}
.record_table {
  display: table;
  width: 100%;
  margin: 00px 0px;
}
.record_tr {
  display: table-row;
}
.record_td {
  cursor: pointer;
  display: table-cell;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 1.8;
  vertical-align: middle;
}
.record_td .td {
  line-height: 1.8;
}
.record_tr:nth-child(2n) {
  background: #f7fcff;
}
.audit-list {
  .audit-list-btn {
    height: 36px;
    border-radius: 4px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 36px;
    border: none;
    outline: none;
    position: relative;
    z-index: 11;
  }
  .audit-list-btn.blue {
    background: #3273f6;
    color: #fff;
  }
  .audit-list-btn.red {
    background: #fddfdf;
    color: #ff5151;
  }
  .audit-item {
    border-bottom: 1px solid #ebebeb;
    padding: 18px 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .item-l {
      flex: 1;
      display: flex;
      align-items: center;
      .item-flex {
        display: flex;
        flex-wrap: wrap;
      }
      .item-td {
        font-size: 16px;
        line-height: 1.6;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .item-date {
      font-size: 14px;
      color: #999;
      min-width: 120px;
      text-align: right;
    }
  }
  .audit-item:nth-last-child(1) {
    border: none;
  }
}
</style>
